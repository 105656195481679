import React, { Component } from 'react';
import { withSize } from 'react-sizeme';

import Button from '../components/Button';
import Layout from '../components/Layout';
import PageTitleBanner from '../components/PageTitleBanner';

import BenefitsStyled from '../components/page-styles/benefits-styles';
import SEO from '../components/SEO';

class Benefits extends Component {
    constructor(props) {
        super(props);

        this.state = {
            truckCount: null,
            paymentMethod: null,
            country: null,
        };
    }
    render() {
        const { size } = this.props;
        const featurArr = [
            {
                id: 'line-credit',
                imgPath: require('../images/axlecard_nofee_icon.png'),
                featureCopy: 'Zero Hidden Fees',
                featureBody: 'No transaction, account management, annual, or monthly fees'
            },
            {
                id: 'fuel-purchases',
                imgPath: require('../images/axlecard_fuel_icon.png'),
                featureCopy: '24-Hour Approval',
                featureBody: 'Apply today<br /> Fuel tomorrow'
            },
            {
                id: 'flexible-icon',
                imgPath: require('../images/axlecard_lock_icon.png'),
                featureCopy: 'Peace-of-Mind Security',
                featureBody: 'Manage locations, set card limits, and easily monitor activity'
            },
            {
                id: 'hidden-fee-icon',
                imgPath: require('../images/axle-cost-saving-rewards.png'),
                featureCopy: 'Cost-Saving Rewards',
                featureBody: 'Earn an extra reward point per gallon and cash back on diesel and DEF purchases'
            },
            {
                id: 'pump-icon',
                imgPath: require('../images/axle-enhanced-payment-terms.png'),
                featureCopy: 'Easy Payment Processing',
                featureBody: 'Fewer fees <br /> More flexibility'
            },
            {
                id: 'group-task-icon',
                imgPath: require('../images/axle-personalized-service.png'),
                featureCopy: 'Personalized Service',
                featureBody: 'Get a dedicated account rep and access to our revamped customer portal'
            },
        ];
        const features = featurArr.map(item => {
            return (
                <div
                    id={item.id}
                    key={item.id}
                    className="benefits__company__info__features__card"
                >
                    <div className="feature__icon_container">
                        <img alt={item.id} src={item.imgPath} />
                    </div>
                        <h5 dangerouslySetInnerHTML={{ __html: item.featureCopy }} />
                        <p dangerouslySetInnerHTML={{ __html: item.featureBody }} className="featureBody" />
                </div>
            );
        });
        return (
            <Layout hideNav={true} needsSolidHeader={true} size={size}>
                <BenefitsStyled>
                    <SEO title="Benefits" />
                    <PageTitleBanner title="Apply to join the One9 Fuel Network" />
                    <h3>Axle Fuel Card&reg;: the ultimate fuel card for fleets</h3>
                    <div className="benefits__content">
                        <p>
                            One9 and Pilot Flying J provide the largest fueling network in North America. 
                            With over 800 locations at your service, the next freight, the next haul, the next job, 
                            and the next hire just got closer. Customized credit solutions are waiting. 
                            It’s time to pull ahead.
                        </p>
                    </div>
                    <div className="benefits__rectangle__container">
                        <div className="benefits__card__container">
                            <img
                                alt="axle-fuel-card"
                                src={require('../images/axle-fuel-card.png')}
                            />
                        </div>
                        <div className="benefits__content">
                            <div className="benefits__content__axle__fuel__head">
                                <h4>
                                    Explore the benefits of Axle Fuel Card&reg; and One9
                                </h4>
                            </div>
                            <div className="benefits__feature_container">
                                {features}
                            </div>
                            <div className="benefits__get__started_container">
                                <i className="left__arrow__1"></i>
                                <i className="left__arrow__2"></i>
                                <i className="left__arrow__3"></i>
                                <i className="left__arrow__4"></i>
                                <Button
                                    color="yellow"
                                    url="/creditapplication"
                                    text="Get Started"
                                />
                                <i className="right__arrow__1"></i>
                                <i className="right__arrow__2"></i>
                                <i className="right__arrow__3"></i>
                                <i className="right__arrow__4"></i>
                            </div>

                            {/* TODO: Add the contact us page link */}
                            <div className="benefits__get__started_container">
                                <a href="/contactus">
                                    <h5>
                                        get in touch with a representative >
                                    </h5>
                                </a>
                            </div>
                        </div>
                    </div>
                        <p className="benefits__subjectToCredit__copy">
                            Subject to credit approval. Terms and conditions apply. 
                            The Axle Fuel Card&reg; is accepted at Pilot and Flying J Travel Centers, 
                            the One9 Fuel Network, and at Southern Tire Mart at Pilot Flying J Truck Care Service Centers. 
                            Accounts are available for commercial use only including diesel and mixed fuel fleets, 
                            but gas only fleets are no eligible for approval.
                    <br /> <br />
                            You will receive one bonus myRewards loyalty point for each gallon of commercial diesel fuel purchased at One9, 
                            Pilot and Flying J locations when you use your Axle Fuel Card. You must present your myRewards loyalty card or 
                            the phone number associated with myRewards loyalty account at the time of purchase. DEF not included. 
                            Does not apply to in-store purchases. myRewards terms and conditions apply. Void where prohibited or otherwise restricted.
                        </p>
                </BenefitsStyled>
            </Layout>
        );
    }
}

export default withSize()(Benefits);
