import styled from 'styled-components';

const EditorialContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 0;
    align-self: auto;
    width: 100%;
    margin-bottom: 72px;

    @media screen and (max-width: 45em) {
        margin-bottom: 32px;
    }

    h3 {
        width: 100%;
        margin-top: 40px;
        line-height: 43px;

        @media screen and (max-width: 45em) {
            width: 70%;
            line-height: 26px;
        }
    }
    .benefits__content {
        align-items: flex-start;
        width: 78%;
        margin-bottom: 79px;

        @media screen and (max-width: 45em) {
            margin-bottom: 72px;
        }
        .benefits__content__axle__fuel__head {
            width: 100%;
            display: flex;
            justify-content: center;
            h4 {
                color: #fff;
                margin-bottom: 48px;

                @media screen and (max-width: 45em) {
                    width: 75%;
                    line-height: 31px;
                }
            }
        }
        p {
            width: 100%;
            margin: 32px 0 65px 0;
            color: #1d2521;
            font-family: ${props => props.theme.fonts[0]}, sans-serif;
            font-size: 18px;
            letter-spacing: -0.3px;
            line-height: 34px;
            text-align: center;

            @media screen and (max-width: 45em) {
                margin: 25px 0 47px 0;
                line-height: 24px;
            }
        }
    }

    .benefits__rectangle__container {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 80%;
        height: auto; //825px;
        border-radius: 12px;
        background-color: ${props => props.theme.colors.primaryGray};

        @media screen and (max-width: 45em) {
            width: 100%;
            height: auto; //1451px;
            border-radius: 0px;
        }
    }

    .benefits__card__container {
        margin: -120px auto;

        img {
            margin: 0 0 150px 90px;
        }

        @media screen and (max-width: 45em) {
            margin: -80px auto;

            img {
                width: 284px;
                height: 168px;
                margin: 0 0 100px 50px;
            }
        }
    }

    .benefits__feature_container {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;

        .benefits__company__info__features__card {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 33.33%;
            color: #fff;
            margin-bottom: 48px;

            p {
                width: 90%;
                color: #ffffff;
                font-family: ${props => props.theme.fonts[0]}, sans-serif;
                font-size: 16px;
                letter-spacing: -0.39px;
                line-height: 24px;
                text-align: center;
                margin-top: 0px;
            }

            .featureBody {
                height: 100px;
                margin-top: 15px;
            }

            @media screen and (max-width: 45em) {
                width: 100%;
                margin-bottom: 24px;

                p {
                    width: 54%;
                }
            }
        }

        .feature__icon_container {
            width: 64px;
            height: 64px;
            background-color: ${props => props.theme.colors.primaryYellow};
            margin-bottom: 15px;
            border-radius: 4px;

            img {
                width: 39px;
                height: 39px;
                margin: 13px 12px;
            }
        }
    }

    .benefits__get__started_container {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;

        @media screen and (max-width: 45em) {
            width: 98%;
        }

        i {
            display: inline-block;
            border: solid #fff;
            border-width: 0 5px 5px 0;
            width: 12px;
            height: 12px;
            margin: 4px;

            @media screen and (max-width: 45em) {
                display: none;
            }
        }

        .left__arrow__1 {
            opacity: 0.1;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }

        .left__arrow__2 {
            opacity: 0.3;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }

        .left__arrow__3 {
            opacity: 0.6;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }

        .left__arrow__4 {
            opacity: 0.9;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }

        .right__arrow__1 {
            opacity: 0.9;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
        }

        .right__arrow__2 {
            opacity: 0.6;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
        }

        .right__arrow__3 {
            opacity: 0.3;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
        }

        .right__arrow__4 {
            opacity: 0.1;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
        }

        div {
            height: 59px;
            width: 310px;
            margin: 0 16px;
            align-items: center;
        }

        h5 {
            color: #fff;
            font-weight: 100;
            font-size: 12px;
            margin-top: 24px;
            padding-bottom: 5px;
            border-bottom: 1px solid
                ${props => props.theme.colors.primaryYellow};
        }
    }

    .benefits__subjectToCredit__copy {
        width: 60%;
        margin-top: 57px;
        color: #1d2521;
        font-family: ${props => props.theme.fonts[0]}, sans-serif;
        font-size: 10px;
        letter-spacing: -0.24px;
        line-height: 22px;
        text-align: center;

        @media screen and (max-width: 45em) {
            width: 90%;
            margin-top: 32px;
        }
    }

    #hidden-fee-icon {
        margin-top: 0px;

        @media screen and (max-width: 45em) {
            margin-top: 0px;
        }
    }

    @media screen and (min-width: 1024px) {
        h1 {
            width: 100%;
            margin-top: 0;
            border-radius: 0;
            box-shadow: none;
            font-size: 44px;
        }

        .benefits__content {
            width: 92%;
            max-width: 800px;
        }

        p {
            font-size: 18px;
        }
    }
`;

export default EditorialContentStyled;
